import React, { useEffect, useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/icons/zensory_logo.svg";
import eye from "../../assets/icons/eye-regular.svg";
import eye_slash from "../../assets/icons/eye-slash-regular.svg";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { isKeyIn, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import {
  checkEmailFormat,
  checkNameFormat,
  checkNewPasswordFormat,
  isNullOrEmpty,
} from "../Data/Util/util";
import SpinnerLoader from "../SpinnerLoader";
import { KeyId } from "../UI/KeyEnum";
import AuthErrorPopup from "../AuthErrorPopup";
import { LOGGER, LogLevel } from "../Util/Logger";
import { getConfigLink, isLinkId } from "../Data/constants";
import { isWorkEmail } from "../Util/AuthHelper";

const SignUpPage = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [nameErrorDialog, showNameErrorDialog] = useState(false);
  const [emailErrorDialog, showEmailErrorDialog] = useState(false);
  const [passwordErrorDialog, showPasswordErrorDialog] = useState(false);
  const [emailErrorDescription, setEmailErrorDescription] = useState();
  const [nameErrorDescription, setNameErrorDescription] = useState();
  const [passwordErrorDescription, setPasswordErrorDescription] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const config = getConfiguration();

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenSignUp);
  }, []);

  useEffect(() => {
    async function hasUser() {
      const hasUser = await ZensoryModule.getAuth()
        .hasUser(true)
        .then((res) => {
          return res;
        });
      if (hasUser) {
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
      }
      setIsLoggedIn(false);
      LOGGER.log(LogLevel.DEBUG, `hasUser=${hasUser}`);
    }
    hasUser();
  }, [isLoggedIn]);

  const onNameChange = (text) => {
    if (!checkNameFormat(text)) {
      showNameErrorDialog(true);
      setNameErrorDescription(getText(TextId.nameErrorDescription));
    } else {
      showNameErrorDialog(false);
    }
    setName(text);
  };

  const onEmailChange = (text) => {
    if (!checkEmailFormat(text)) {
      showEmailErrorDialog(true);
      setEmailErrorDescription(getText(TextId.emailErrorDescription));
    } else {
      if (config.isWorkEmailOnly && !isWorkEmail(text)) {
        showEmailErrorDialog(true);
        setEmailErrorDescription(getText(TextId.emailNotWorkErrorDescription));
      } else {
        showEmailErrorDialog(false);
      }
    }
    setEmail(text);
  };

  const onPasswordChange = (text) => {
    if (!checkNewPasswordFormat(text)) {
      showPasswordErrorDialog(true);
      setPasswordErrorDescription(getText(TextId.passwordErrorDescription));
      setPassword(text);
    } else {
      setPassword(text);
      showPasswordErrorDialog(false);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    preventDefault(e);
    const isSignedUp = await ZensoryModule.getAuth()
      .signup(email, password, name)
      .catch((error) => {
        setLoginErrorMessage(error.message);
        setShowLoginPopup(true);
      });
    if (isSignedUp) {
      //set logged in to redirect to logged in homepage
      setIsLoggedIn(true);
    }
    setLoading(false);
  };

  const handleLinkClick = (id) => {
    const configLink = getConfigLink(id, RouteId.Login);
    
    if (configLink === RouteId.Login) {
      ZensoryModule.getNavigationHandler().navigate(window, RouteId.Login);
      return;
    }

    window.open(configLink, isLinkId(id) ? "_blank" : null);
  }

  const transformText = (node, children) => {
    if (node.tagName.toLowerCase() === "a") {
      return (
        <a
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "white",
          }}
          onClick={(e) => {
            preventDefault(e);
            handleLinkClick(node.id);
          }}
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
  };

  // handle return key presses on the password visibility toggle
  const _handleKeydownPasswordVisibility = (e) => {
    stopPropagation(e);
    if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
      setShowPassword(!showPassword);
    }
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  return (
    <>
      <div className="login-container">
        {showLoginPopup ? (
          <AuthErrorPopup
            setShowAuthErrorPopup={setShowLoginPopup}
            errorMessage={loginErrorMessage}
          />
        ) : null}
        <div className="header-row-login">
          <div className="logo-container">
            <img className="logo" src={logo} alt="Zensory logo" />
          </div>
        </div>
        <div className="login-content-container">
          <h1 className="signup-heading">
            <Interweave content={getText(TextId.signupSubtitle)} />
          </h1>

          <form className="input-container" onSubmit={handleSubmit}>
            <div className="input-field">
              <div className="input-title"> Name </div>
              <input
                className=""
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required={true}
                value={name}
                onChange={(e) => onNameChange(e.target.value)}
              />
            </div>
            {nameErrorDialog ? (
              <span className="text-danger">{nameErrorDescription}</span>
            ) : null}
            <div className="input-field">
              <div className="input-title"> Email </div>
              <input
                className=""
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required={true}
                value={email}
                onChange={(e) => onEmailChange(e.target.value)}
              />
            </div>

            {emailErrorDialog ? (
              <span className="text-danger">{emailErrorDescription}</span>
            ) : null}

            <div className="input-field">
              <div className="input-title"> Password </div>
              <input
                className=""
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                required={true}
                value={password}
                autoComplete="current-password"
                onChange={(e) => onPasswordChange(e.target.value)}
              />
              <img
                id="btn-show-password"
                className="password-toggle"
                alt={showPassword ? "Hide Password" : "Show Password"}
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="0"
                src={showPassword ? eye_slash : eye}
                onKeyDown={(e) => _handleKeydownPasswordVisibility(e)}
              />
            </div>

            {passwordErrorDialog ? (
              <span className="text-danger">{passwordErrorDescription}</span>
            ) : null}

            <button
              disabled={
                nameErrorDialog ||
                passwordErrorDialog ||
                emailErrorDialog ||
                showLoginPopup ||
                isNullOrEmpty(email) ||
                isNullOrEmpty(password)
              }
              className="next-button"
              style={{ position: "relative" }}
            >
              {loading ? <SpinnerLoader /> : "Sign Up"}
            </button>
          </form>
          <div className="info-container">
            <div className="sign-up-text">
              <Interweave
                content={getText(TextId.loginText)}
                transform={transformText}
              />
            </div>
            <div className="terms-of-service">
              <Interweave
                content={getText(TextId.termsOfService)}
                transform={transformText}
              />
            </div>
          </div>
          <div
            className="web-version"
            id="version-login"
            onClick={(e) => onVersionClicked(e)}
          >
            <Interweave content={`Version: ${getConfiguration().version}`} />
          </div>
        </div>
      </div>
    </>
  );
};

const SignUp = withScorm()(SignUpPage);
export default SignUp;
